.header {
    display: flex;
    justify-content: space-between;
}
.logo {
    width: 20%;
    height: auto;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 25px;
    color: #fff;
}
.header-menu li:hover {
    color: var(--orange);
    cursor: pointer;
}
@media screen and (max-width: 768px) {
.header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
}
.header-menu {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
}
.logo {
    width: 60%;
    height: auto;
}
    
}