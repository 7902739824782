.plans-container {
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: #fff;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}
.plan button {
    width: 100%;
}
.plan button span {
    display: none;
    transition: all .5s;
}
.plan button:hover span {
    display: block;
}
.plan:nth-child(2) {
    background: var(--planCard);
    transform: scale(1.1);
    font-size: 1rem;
    font-weight: bold;
}
.plan>svg {
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(3) {
    font-size: 2.5rem;
    font-weight: bold;
}
.plan:nth-child(5){
    font-size: .8rem;
}
.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature img {
    width: 1rem;
}
.plan:nth-child(2)>svg {
    fill: #fff;
}
.plan:nth-child(2) button {
    color: orange;
}

.plans-blur-1 {
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}
.plans-blur-2 {
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0;
}

@media screen and (max-width: 768px) {
    .plans {
        flex-direction: column;
    }
}