.programs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0px 25px;
}
.programs-header {
    display: flex;
    gap: 10%;
    justify-content: center;
    color: #fff;
    font-size: 50px;
    text-transform: uppercase;
    font-style: italic;
}
.program-categories {
    display: flex;
    gap: 20px;
}
.category {
    display: flex;
    flex-direction: column;
    background: gray;
    padding: 30px;
    gap: 20px;
    justify-content: space-between;
    color: #fff;
}
.category > :nth-child(1){
    width: 50px;
    height: 30px;
    fill: #fff;
}
.category > :nth-child(2){
    font-size: 20px;
    font-weight: bold;
}
.category > :nth-child(3){
    font-size: 15px;
    line-height: 25px;
}
.join-now {
    display: flex;
    align-items: center;
    gap: 40px;
}
.join-now img {
    width: 20px;
}
.category:hover {
    background: var(--planCard);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .programs-header {
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-categories {
        flex-direction: column;
    }

}