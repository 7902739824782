.Hero {
    display: flex;
    justify-content: space-between;
}
.left-h {
    padding: 20px;
    flex: 3;
    display: flex;
    gap: 25px;
    flex-direction: column;
}
.right-h {
    flex: 1;
    position: relative;
    background: var(--orange);
    height: 100vh;
}
.the-best-ad {
    margin-top: 30px;
    background: #363d42;
    border-radius: 30px;
    width: fit-content;
    padding: 20px 30px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}
.the-best-ad span {
    z-index: 2;
}
.the-best-ad div {
    position: absolute;
    background: var(--orange);
    width: 100px;
    height: 80%;
    left: 8px;
    border-radius: 25px;
    z-index: 1;
}
.hero-text {
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: bold;
    text-overflow: inherit;
    color: #fff;
}
.hero-text p {
    font-size: 18px;
    text-transform: none;
    font-weight: 200;
    letter-spacing: 1px;
    margin-top: 10px;
}

.figures{
    display: flex;
    gap: 35px;
}
.figures div {
    display: flex;
    flex-direction: column;
}
.figures div span:nth-of-type(1) {
    color: #fff;
    font-size: 25px;
}
.figures div span:nth-of-type(2) {
    color: var(--gray);
    text-transform: uppercase;
}

.hero-buttons {
    display: flex;
    gap: 25px;
    font-weight: normal;
}
.hero-buttons .btn:nth-child(1) {
    color: #fff;
    background: var(--orange);
    width: 15%;
}
.hero-buttons .btn:nth-child(1):hover {
    background: transparent;
    border: 2px solid orange;
}
.hero-buttons .btn:nth-child(2) {
    color: #fff;
    background: transparent;
    width: 15%;
    border: 2px solid orange;
}
.hero-buttons .btn:nth-child(2):hover {
    background: var(--orange);
}

.right-h .btn {
    position: absolute;
    top: 5%;
    right: 10%;
}
.heart-rate {
    display: flex;
    flex-direction: column;
    gap: 18px;
    background-color: var(--darkGrey);
    width: fit-content;
    border-radius: 5px;
    padding: 15px;
    align-items: start;
    position: absolute;
    right: 20%;
    top: 20%;
}
.heart-rate img {
    width: 50%;
}
.heart-rate span:nth-child(2) {
    color: var(--gray);
}
.heart-rate span:nth-child(3) {
    color: #fff;
    font-size: 20px;
}
.hero-image {
    position: absolute;
    width: 100%;
    top: 20%;
    right: 50%;
}
.hero-image-back {
    position: absolute;
    width: 70%;
    top: 0;
    right: 100%;
    z-index: -1;
}
.calories {
    display: flex;
    gap: 30px;
    background: var(--caloryCard);
    border-radius: 5px;
    padding: 15px;
    width: fit-content;
    position: absolute;
    top: 70%;
    left: -120%;
}
.calories img {
    width: 20%;
}
.calories div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories div span:nth-child(1){
    color: var(--gray);
}
.calories div span:nth-child(2){
    color: #fff;
    font-size: 20px;
}

.hero-blur {
    width: 22rem;
    height: 30rem;
    left: 0;
}

@media screen and (max-width: 768px) {
    .Hero {
        flex-direction: column;
    }
    .hero-blur {
        width: 14rem;
    }
    .the-best-ad {
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(.8);
    }
    .hero-text {
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .hero-text div p {
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .hero-buttons {
        justify-content: center;
        width: 100%;
    }
    .hero-buttons .btn:nth-child(1),
    .hero-buttons .btn:nth-child(2) {
        width: 50%;
    }
    .figures div span:nth-of-type(1) {
        font-size: large;
    }
    .figures div span:nth-of-type(2) {
        font-size: small;
    }
    .right-h {
        position: relative;
        background: none;
    }
    .heart-rate {
        top: 1rem;
        left: 1rem;
    }
    .calories {
        position: relative;
        top: 5rem;
        left: 2rem;
    }
    .calories img {
        width: 2rem;
    }
    .calories div:nth-child(2){
        color: #fff;
        font-size: 1rem;
    }
    .hero-image {
        position: relative;
        width: 15rem;
        left: 7rem;
        top: 4rem;
        align-self: center;
    }
    .hero-image-back {
        width: 15rem;
        left: 2rem;
        top: 0;
    }
}